<template>
	<div>
		<!-- 公共头部 -->
	<Header></Header>
		
		<div class="section1">
			<div class="box">
				<h2 class="title">{{messageInfo.title}}</h2>
				<div class="info">
					<time class="time">{{messageInfo.type == 1 ? '管理员':'系统消息'}}</time>
					<span class="num">{{messageInfo.addtime}}</span>
				</div>
			</div>
			<div class="singlepage" v-html="messageInfo.content"></div>
		</div>
		
		<!-- 公共底部 -->
		<!-- <Footer></Footer> -->
	</div>
</template>

<script>
	import Header from '@/components/Public/Header.vue';
	import Footer from '@/components/Public/Footer.vue';
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				title: '',
				messageInfo: ''
			}
		},
		computed: {
			uid() {
				return this.$store.state.uid;
			}
		},
		activated() {
			this.getUserInfo();
		},
		deactivated() {
			this.messageInfo = '';
		},
		methods: {
			async getMessageInfo() {
				var id = this.$route.query.id;
				var uid = this.uid;
				var data = {
					id, uid
				};
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/user/messageDetail', { params: data });
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('消息通知 消息详情：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					res.data['addtime'] = this.utils.js_date_time(res.data['addtime'],4);
					this.messageInfo = res.data;
				} else {
					this.messageInfo = '';
				}
			},
			// 获取用户信息
			async getUserInfo(){
				if(!this.uid){
					const confirmResult = await this.$messageBox.confirm('您当前未登录，请登录后再进行操作！', '登录提醒').catch(error => error);
					if(confirmResult != 'confirm'){
								wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
						return;
					}
							wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
				} else {
					this.getMessageInfo();
				}
			},
		},
	};
</script>

<style scoped>
	.section1{
		margin-top: 0.3rem;
		background: #ffffff;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		padding: 0.3rem;
		margin-bottom: 0.4rem;
	}
	.section1 .box{
		margin-bottom: 0.4rem;
	}
	.section1 .box .title{
		font-size: 0.32rem;
		color: #333333;
	}
	.section1 .box .info{
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 0.3rem;
	}
	.section1 .box .info .time{
		font-size: 0.26rem;
		color: #969897;
	}
	.section1 .box .info .num{
		font-size: 0.26rem;
		color: #969897;
	}
	.section1 .singlepage{
		font-size: 0.26rem;
		color: #333333;
		line-height: 0.44rem;
	}
</style>